import React from "react";
import './signupfooterstyle.css';

function SignUp({ profile }) {
  const handleClick = () => {
    if (profile) {
      localStorage.setItem('profile', profile);
    } else {
      localStorage.setItem('profile', '');
    }
    window.location.href = '/Student_Registration';
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className="SignupContainer">
        <div className="Signupbox">
          <h1 className="Signupboxhead">Ready to join the success stories?</h1>
          <p className="Signupboxpara">Start your journey with us today and unlock endless opportunities for your career growth.</p>
          <button className="Signupboxbutton" onClick={handleClick}>Sign up now</button>
        </div>
      </div>
    </>
  );
}

export default SignUp;